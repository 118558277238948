<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="teding" colspan="2">
            <img class="teding-img" src="@/assets/image/teding.jpg" />
          </td>
          <td class="customize-title1" colspan="14">
            关于沙特地区中国籍员工个人社保两金及个税清缴的通知
          </td>
        </tr>
        <tr>
          <td class="text-left" colspan="8">日期:</td>
          <td class="text-right" colspan="8">编号:</td>
        </tr>
        <tr>
          <td class="text-left" colspan="16">
            <div class="div-title"><span>关联姓名</span>，您好！</div>
            <div class="div-content">
              根据您与公司签订的《派驻海外工作员工社保公积金年金个税代缴委托书》，自本通知发出之日起当月25日前，请将南京工程公司在此期间为您垫付的应由您个人缴纳的社保、公积金、个税等代缴金额，足额转账至南工国内指定账户。
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="16">
            员工社会保险、两金个人应缴额及个人所得税应缴额明细
          </td>
        </tr>
        <tr>
          <td class="text-center">序号</td>
          <td class="text-center">员工编号</td>
          <td class="text-center">员工姓名</td>
          <td class="text-center">身份证号码</td>
          <td class="text-center">用工形式</td>
          <td class="text-center">项目名称</td>
          <td class="text-center">用工单位</td>
          <td class="text-center">清缴月份</td>
          <td class="text-center text-bold">应发合计(元)</td>
          <td class="text-center text-bold">住房公积金个人(元)</td>
          <td class="text-center text-bold">养老保险个人(元)</td>
          <td class="text-center text-bold">失业保险个人(元)</td>
          <td class="text-center text-bold">医疗保险个人(元)</td>
          <td class="text-center text-bold">年金个人(元)</td>
          <td class="text-center text-bold">个人所得税 (元)</td>
          <td class="text-center text-bold">应缴纳金额 (元)</td>
        </tr>
        <tr>
          <td class="text-center text-size" rowspan="6">1</td>
          <td class="text-center text-size" rowspan="6"></td>
          <td class="text-center text-size" rowspan="6"></td>
          <td class="text-center text-size" rowspan="6"></td>
          <td class="text-center text-size" rowspan="6"></td>
          <td class="text-center text-size" rowspan="6"></td>
          <td class="text-center text-size" rowspan="6"></td>
          <td class="text-center text-size">2024年1月</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
        </tr>
        <tr>
          <td class="text-center text-size">2024年2月</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
        </tr>
        <tr>
          <td class="text-center text-size">2024年3月</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
        </tr>
        <tr>
          <td class="text-center text-size">小计</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
        </tr>
        <tr>
          <td class="text-center text-size">上次结余</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
        </tr>
        <tr>
          <td class="text-center text-size">季度应缴额</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
          <td class="text-center text-size text-red">0</td>
        </tr>
        <tr>
          <td class="text-left text-50" colspan="8">
            <div class="herder-title">汇款账户如下：</div>
            <div>单位名称：中石化南京工程有限公司</div>
            <div>开户行：建设银行南京市尧化门支行</div>
            <div>账号：32001597338052500888</div>
            <div>银行码：105301000909</div>
            <div class="div-bold">
              汇款须附言注明：***(姓名)清缴7月社保两金个税
            </div>
          </td>
          <td class="text-left text-50" colspan="8">
            <div class="herder-title">注意事项：</div>
            <div>1、如未按约定时间清缴费用，自次月起将暂停发放绩效奖金</div>
            <div>
              2、请严格按照通知金额（含小数点后金额）缴费，勿要取整多缴或少缴。
            </div>
            <div>
              3、请勿通过薪资收款账户换汇后直接转账公司，以免因（人员或时段）集中操作，被银行误判为个人分拆换汇行为，影响个人后续结汇。
            </div>
            <div class="div-nobold">4、请将汇款结果截图反馈至联系人邮箱。</div>
          </td>
        </tr>
        <tr>
          <td class="text-left" colspan="11"></td>
          <td class="text-left text-left-bold" colspan="5">
            中东公司人力资源部
          </td>
        </tr>
        <tr>
          <td class="text-left" colspan="11"></td>
          <td class="text-left text-left-bold">联系人：</td>
          <td class="text-left" colspan="4"></td>
        </tr>
        <tr>
          <td class="text-left" colspan="11"></td>
          <td class="text-left text-left-bold">电 话：</td>
          <td class="text-left" colspan="4"></td>
        </tr>
        <tr>
          <td class="text-left" colspan="11"></td>
          <td class="text-left text-left-bold">邮 箱：</td>
          <td class="text-left" colspan="4"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { getNewDate } from "@/utils/date";
import {
  allCostCenters,
  getProjectAll,
  getCompanyAll,
  getDepartmentAll,
  getCostCategoryTree,
} from "@/api/user";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
  },
  data() {
    return {
      TableDate: null,
    };
  },

  methods: {
    geteditData(data1, data2, data3, params) {
      console.log("params", params);
      this.projectList = data1;
      this.companyList = data2;
      this.departmentList = data3;
      this.TableDate = params.TableDate;
      this.ListerDept = params.ListerDept;
      this.CostCategoryName = params.CostCategoryName;
      this.Amount = params.Amount;
      this.Duration = params.Duration;
      this.ITMSANO = params.ITMSANO;
      this.BankInformation = params.BankInformation;
      this.BankAccountName = params.BankAccountName;
      this.BankName = params.BankName;
      this.BankAccount = params.BankAccount;
      this.ID = params.ID;
      this.KeyID = params.KeyID;
      this.HROfficer = params.HROfficer;
      this.HRSuperintendent = params.HRSuperintendent;
      this.ExecutiveDirector = params.ExecutiveDirector;
      this.ApplicationNumber = params.ApplicationNumber;
      this.CompanyNameofBeneficiary = params.CompanyNameofBeneficiary;
      this.CRNumberofBeneficiary = params.CRNumberofBeneficiary;
      this.BankCode = params.BankCode;
      this.Note = params.Note;
      this.CostCategory = params.CostCategoryIDs.split(",");
      this.CostCategory = this.CostCategory.map(Number);
      this.CostAllocationDetails.forEach((_, index, Array) => {
        if (params.Details[index]) {
          params.Details[index].CostAllocationType =
            params.Details[index].TypeID;
          Array[index] = JSON.parse(JSON.stringify(params.Details[index]));
          Array[index].CostCenterList = [];
          Array[index].SubProjeList = [];
          console.log("11111", this.CostAllocationDetails);
          allCostCenters({
            projectId: Array[index].ProjectID,
            companyId: Array[index].CompanyID,
            deptId: Array[index].DepartmentID,
          }).then((res) => {
            if (res.status === 200) {
              Array[index].CostCenterList = res.response;
              if (res.response.length === 1) {
                Array[index].CostCenterID = res.response[0].Id;
                Array[index].CostCenterCode = res.response[0].Code;
              }
            }
          });
          this.getProjectsChildren(index, Array[index].ProjectID);
        }
      });
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
      console.log("11111", this.CostAllocationDetails);
    },
  },
  created() {
    this.TableDate = getNewDate();
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });

    getCostCategoryTree().then((res) => {
      if (res.status === 200) {
        this.tree = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .tbody {
    width: 100%;
    position: relative;
  }
  .teding {
    max-width: 100%;
  }
  .teding-img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    max-width: 200px;
  }
  .customize-title1 {
    font-size: 30px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      padding: 10px 5px;
    }
    .text-center {
      text-align: center;
      font-size: 14px;
      padding: 10px 5px;
      .el-select {
        max-width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
    .text-bold {
      font-weight: bolder;
    }
    .text-size {
      font-size: 12px;
    }
    .text-title {
      text-align: center;
      font-size: 14px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 14px;
      padding: 10px 5px;
    }
    .text-red {
      color: red;
    }
    .text-left {
      text-align: left;
      font-size: 14px;
      padding: 10px 5px;
      line-height: 18px;
      .div-title {
        margin-top: 16px;
        // padding-left: 10px;
        span {
          // color: red;
          font-weight: bold;
        }
      }
      .div-content {
        // padding-left: 10px;
        text-indent: 1em;
        margin-top: 5px;
        margin-bottom: 16px;
      }
      .herder-title {
        font-weight: bolder;
        margin-top: 20px;
        margin-bottom: 10px;
      }
      .div-bold {
        font-weight: bolder;
        margin-bottom: 20px;
      }
      .div-nobold {
        margin-bottom: 20px;
      }
    }
    .text-50 {
      min-width: 50% !important;
    }
    .text-left-bold {
      font-weight: bolder;
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}
</style>